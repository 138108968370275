@import "../../_globalColor";

.talks-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  position: center;
}

.talks-title {
  margin-bottom: 2rem;
  color: $titleColor;
  font-size: 2.5rem;
}

.talks-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.talk-card {
  flex: 0 0 30%; // Each card takes up about 30% of the container width
  transition: opacity 0.5s ease, transform 0.5s ease;
  text-align: center;
  margin: 0 1rem;
  opacity: 0; // Default to fully hidden
  transform: scale(1); // Default scale
  display: none; // Default to not displayed
}

.talk-image {
  margin-bottom: 1rem;
  border-radius: 10px;
}

.card-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: $textColor;
}

.card-description {
  font-size: 1rem;
  color: $textColor;
}

.slide-button {
  background-color: $buttonColor;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  margin: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &.prev {
    left: 10px;
  }

  &.next {
    right: 10px;
  }

  &:hover {
    background-color: $buttonColor;
  }
}

.dark-mode {
  background-color: $darkBackground;
  color: $textColorDark;
}
